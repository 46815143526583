.products-slide-container {
  display: grid;
  white-space: nowrap;
  /* margin-top: 4%; */
  /* scroll-behavior: smooth; */
  /* overflow-x: scroll; */
  /* width: 100%; */
  /* scroll-snap-type: x mandatory; */
  /* position: relative; */
}
.Media-Card-Container {
  margin-right: 10px;
  /* margin-bottom: 17px; */
  width: 100%;
  height: 100%;
  position: relative;
}
.Media-Card-Container img {
  /* border-radius: 7px; */
  /* display: inline-flex; */
  max-width: 98vw;
  max-height: 500px;
  background-color: white;
  overflow: hidden;
}
