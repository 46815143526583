@tailwind base;
@tailwind components;
@tailwind utilities;

* ::-webkit-scrollbar {
  display: none;
  /* visibility: hidden; */
}

*.--ms-overflow-style {
 display: none; 
}

*.--scrollbar-width {
 display: none; 
 /* Firefox */
}

* {
  font-family: "Poppins", sans-serif;
  outline: none;
  --tw-ring-color: #ffffff00;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  border-color: #ffffff00;

  --tw-ring-color: #ffffff00;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  border-color: #ffffff00;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

* input[type="text"]:focus {
  font-family: "Poppins", sans-serif;
  border: 1px solid #ffffff00;
  --tw-ring-color: #ffffff00;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
